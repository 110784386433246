import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Modal from 'react-modal';
import { IntlProvider } from 'react-intl';
import { messages } from './messages';
import { HelmetProvider } from 'react-helmet-async';

// Initialisation de react-modal
Modal.setAppElement('#root');

const language = navigator.language.split(/[-_]/)[0]; // Obtenir la langue du navigateur

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
    <HelmetProvider>
      <IntlProvider locale={language} messages={messages[language]}>
        <App />
      </IntlProvider>
    </HelmetProvider>
 // </React.StrictMode>
);

reportWebVitals();
