import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddQuestionForm.css'; // Importez le fichier CSS

const AddQuestionForm = () => {
  const location = useLocation();
  const { quizDetails } = location.state || {};
  const { quizId, numQuestions } = quizDetails || {};
  const [questions, setQuestions] = useState(Array.from({ length: numQuestions || 0 }, () => ({
    questionText: '',
    answerOptions: [
      { answerText: '', isCorrect: false },
      { answerText: '', isCorrect: false },
      { answerText: '', isCorrect: false },
      { answerText: '', isCorrect: false },
    ],
  })));
  const navigate = useNavigate();

  const handleQuestionTextChange = (index, e) => {
    const newQuestions = [...questions];
    newQuestions[index].questionText = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerOptionChange = (qIndex, aIndex, e) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answerOptions[aIndex].answerText = e.target.value;
    setQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (qIndex, aIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answerOptions = newQuestions[qIndex].answerOptions.map((option, i) => ({
      ...option,
      isCorrect: i === aIndex,
    }));
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/quiz/${quizId}/add-questions`, { questions });
      setQuestions(Array.from({ length: numQuestions }, () => ({
        questionText: '',
        answerOptions: [
          { answerText: '', isCorrect: false },
          { answerText: '', isCorrect: false },
          { answerText: '', isCorrect: false },
          { answerText: '', isCorrect: false },
        ],
      })));
      navigate('/admin-dashboard', { state: { message: `Quiz ajouté avec succès!` } });
    } catch (error) {
      console.error('Error adding quiz:', error);
      alert('Failed to add quiz.');
    }
  };

  if (!quizDetails) {
    return <div>Error: Quiz details not found</div>;
  }

  return (
    <div className="add-question-form-container">
      <form onSubmit={handleSubmit} className="add-question-form">
        {questions.map((question, qIndex) => (
          <div key={qIndex} className="question-block">
            <label>Question {qIndex + 1}:</label>
            <input
              type="text"
              value={question.questionText}
              onChange={(e) => handleQuestionTextChange(qIndex, e)}
              required
              className="form-control"
            />
            {question.answerOptions.map((option, aIndex) => (
              <div key={aIndex} className="answer-option">
                <label>Answer {aIndex + 1}:</label>
                <input
                  type="text"
                  value={option.answerText}
                  onChange={(e) => handleAnswerOptionChange(qIndex, aIndex, e)}
                  required
                  className="form-control"
                />
                <label className="correct-answer">
                  <input
                    type="radio"
                    name={`correctAnswer${qIndex}`}
                    checked={option.isCorrect}
                    onChange={() => handleCorrectAnswerChange(qIndex, aIndex)}
                  />
                  Correct
                </label>
              </div>
            ))}
          </div>
        ))}
        <button type="submit" className="btn-submit">Add Quiz</button>
      </form>
    </div>
  );
};

export default AddQuestionForm;
