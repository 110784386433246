import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import './GeneralQuizPage.css';

const GeneralQuizPage = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({
    series: false,
    films: false,
    manga: false,
    logique: false,
    star: false,
    cultureGeneral: false,
  });
  const [error, setError] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/quiz`);
        console.log('API Response:', res.data);
        if (Array.isArray(res.data)) {
          setQuizzes(res.data);  // Met à jour avec tous les quizzes
        } else {
          setError('Unexpected API response format');
        }
      } catch (error) {
        setError('Error fetching quizzes: ' + error.message);
      }
    };
    fetchQuizzes();
  }, []);

  const toggleCategory = (category) => {
    setExpandedCategories({
      ...expandedCategories,
      [category]: !expandedCategories[category],
    });
  };

  const renderQuizzesByCategory = (category) => {
    if (error) {
      return <div>{error}</div>;
    }
    const categoryQuizzes = quizzes.filter(quiz => quiz.category === category);
    const visibleQuizzes = expandedCategories[category] ? categoryQuizzes : categoryQuizzes.slice(0, 4);

    return visibleQuizzes.map(quiz => (
      <div key={quiz.id} className="quiz-card">
        <img src={`${process.env.REACT_APP_API_URL}/uploads/${quiz.image}`} alt={quiz.title} className="quiz-image" />
        <h2>{quiz.title}</h2>
        <p>{intl.formatMessage({ id: 'quiz.numQuestions' }, { numQuestions: quiz.numQuestions })}</p>
        {quiz.slug ? (
          <Link to={`/quiz/${quiz.slug}`} className="quiz-button">
            <FormattedMessage id="quiz.startButton" />
          </Link>
        ) : (
          <span>Slug not available</span>
        )}
      </div>
    ));
  };

  const pageTitle = "Quiz Généraux - ABC Quiz";
  const pageDescription = "Découvrez tous nos quiz par catégorie : séries, films, manga, logique, star et culture générale. Testez vos connaissances et amusez-vous avec nos quiz interactifs.";
  const pageUrl = window.location.href;
  const imageUrl = "https://abcquiz.fr/static/media/logo.f19e6135ea883b1bf815.png"; // Remplace par l'URL de l'image de ton choix

  return (
    <div className="general-quiz-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="quiz, séries, films, mangas, logique, star, culture générale, interactif" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <h3>Choisissez votre quiz !</h3>
      <div className="category-section">
        <h3 onClick={() => toggleCategory('series')} className="category-title">
          <FormattedMessage id="category.series" />
        </h3>
        <div className={`quiz-grid ${expandedCategories.series ? 'expanded' : ''}`}>
          {renderQuizzesByCategory('series')}
        </div>
      </div>
      <div className="category-section">
        <h3 onClick={() => toggleCategory('films')} className="category-title">
          <FormattedMessage id="category.films" />
        </h3>
        <div className={`quiz-grid ${expandedCategories.films ? 'expanded' : ''}`}>
          {renderQuizzesByCategory('films')}
        </div>
      </div>
      <div className="category-section">
        <h3 onClick={() => toggleCategory('manga')} className="category-title">
          <FormattedMessage id="category.manga" />
        </h3>
        <div className={`quiz-grid ${expandedCategories.manga ? 'expanded' : ''}`}>
          {renderQuizzesByCategory('manga')}
        </div>
      </div>
      <div className="category-section">
        <h3 onClick={() => toggleCategory('logique')} className="category-title">
          <FormattedMessage id="category.logic" />
        </h3>
        <div className={`quiz-grid ${expandedCategories.logique ? 'expanded' : ''}`}>
          {renderQuizzesByCategory('logique')}
        </div>
      </div>
      <div className="category-section">
        <h3 onClick={() => toggleCategory('star')} className="category-title">
          <FormattedMessage id="category.star" defaultMessage="Quiz Star" />
        </h3>
        <div className={`quiz-grid ${expandedCategories.star ? 'expanded' : ''}`}>
          {renderQuizzesByCategory('star')}
        </div>
      </div>
      <div className="category-section">
        <h3 onClick={() => toggleCategory('cultureGeneral')} className="category-title">
          <FormattedMessage id="category.cultureGeneral" defaultMessage="Culture Générale" />
        </h3>
        <div className={`quiz-grid ${expandedCategories.cultureGeneral ? 'expanded' : ''}`}>
          {renderQuizzesByCategory('cultureGeneral')}
        </div>
      </div>
    </div>
  );
};

export default GeneralQuizPage;
