import React from 'react';
import { Link } from 'react-router-dom'; // Suppression de useLocation
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../assets/images/logo.png';
import './Navbar.css';

const NavigationBar = ({ isLoggedIn = false, handleLogout = () => {} }) => {

  const handleNavClick = () => {
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <Navbar.Brand as={Link} to="/">
        <img
          src={logo}
          height="90"
          className="d-inline-block align-top"
          alt="ABC Quiz Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/quiz" onClick={handleNavClick}>Quiz Général</Nav.Link>
          <Nav.Link as={Link} to="/quiz-series" onClick={handleNavClick}>Quiz Série</Nav.Link>
          <Nav.Link as={Link} to="/quiz-films" onClick={handleNavClick}>Quiz Film</Nav.Link>
          <Nav.Link as={Link} to="/quiz-manga" onClick={handleNavClick}>Quiz Manga</Nav.Link>
          <Nav.Link as={Link} to="/quiz-logique" onClick={handleNavClick}>Quiz Logique</Nav.Link>
          <Nav.Link as={Link} to="/quiz-culture-general" onClick={handleNavClick}>Quiz Culture Général</Nav.Link>
          <Nav.Link as={Link} to="/quiz-star" onClick={handleNavClick}>Quiz Star</Nav.Link>
          <Nav.Link as={Link} to="/contact" onClick={handleNavClick}>Contact</Nav.Link>
        </Nav>
        <Nav>
          {isLoggedIn ? (
            <>
              <Nav.Link as={Link} to="/admin-dashboard" onClick={handleNavClick}>Tableau de Bord Admin</Nav.Link>
              <Nav.Link onClick={() => { handleLogout(); handleNavClick(); }}>Déconnexion</Nav.Link>
            </>
          ) : (
            <Nav.Link as={Link} to="/login" onClick={handleNavClick}>Connexion</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
