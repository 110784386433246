import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';

const AdminDashboard = ({ handleLogout }) => {
  const [quizzes, setQuizzes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/quiz`);
        setQuizzes(response.data);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      }
    };

    fetchQuizzes();
  }, []);

  const handleDelete = async (quizId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/quiz/${quizId}`);
      setQuizzes(quizzes.filter((quiz) => quiz.id !== quizId));
    } catch (error) {
      console.error('Error deleting quiz:', error);
    }
  };

  const handleEdit = (quizId) => {
    navigate(`/edit-quiz/${quizId}`);
  };

  const handleCreateQuiz = () => {
    navigate('/create-quiz');
  };

  const handleCreateAdmin = () => {
    navigate('/register');
  };

  return (
    <div className="admin-dashboard">
      <h1>Tableau de Bord Admin</h1>
      <div className="buttons-container">
        <button onClick={handleCreateQuiz} className="create-quiz-button">Créer un nouveau Quiz</button>
        <button onClick={handleCreateAdmin} className="create-admin-button">Créer un nouvel Administrateur</button>
        <button onClick={handleLogout} className="logout-button">Déconnexion</button>
      </div>
      <table className="quiz-table">
        <thead>
          <tr>
            <th>Titre du Quiz</th>
            <th>Nombre de Questions</th>
            <th>Catégorie</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quizzes.map((quiz) => (
            <tr key={quiz.id}>
              <td>{quiz.title}</td>
              <td>{quiz.quizQuestions ? quiz.quizQuestions.length : 0}</td>
              <td>{quiz.category}</td>
              <td>
                <button onClick={() => handleEdit(quiz.id)} className="edit-button">Modifier</button>
                <button onClick={() => handleDelete(quiz.id)} className="delete-button">Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
