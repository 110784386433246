import React from 'react';
import Modal from 'react-modal';
import './Score.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 10000,  // Assurez-vous que le z-index est suffisamment élevé
  },
};

const Score = ({ score, total, isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Score Modal"
    >
      <h2>Score</h2>
      <p>Vous avez obtenu {score} sur {total}.</p>
      <button onClick={onClose} className="close-button">Fermer</button>
    </Modal>
  );
};

export default Score;
