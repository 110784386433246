import React from 'react';
import { Helmet } from 'react-helmet';
import './PrivacyPolicy.css';  // Importer le fichier CSS

const PrivacyPolicy = () => {
    const pageTitle = "Politique de Confidentialité - Développement-Solution";
    const pageDescription = "Découvrez comment Développement-Solution collecte, utilise et protège vos informations personnelles. Nous respectons votre vie privée.";
    const pageUrl = window.location.href;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content="politique de confidentialité, protection des données, vie privée, Développement-Solution" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:image" content="url-to-your-image" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="url-to-your-image" />
                <link rel="canonical" href={pageUrl} />
            </Helmet>
            <div className="privacy-policy-container">
                <h1>Politique de Confidentialité</h1>
                <p>
                    Nous, [Développement-Solution], respectons votre vie privée et nous engageons à protéger les informations personnelles que vous partagez avec nous. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations.
                </p>
                <h2>Collecte d'Informations</h2>
                <p>
                    <h5>Nous collectons les informations suivantes :</h5>
                    <ul>
                        <li>Informations personnelles Raison sociale : Développement-Solution</li>
                        <li>Forme juridique : Entrepreneur</li>
                        <li>Siège social : 20 Rue Condorcet, 66330 Cabestany</li>
                        <li>SIRET : 847 663 498 00016</li>
                        <li>Téléphone : 06 59 84 28 20</li>
                        <li>Email : developpement.solution@gmail.com</li>
                        <li>Données d'utilisation (ABC Quiz est une application de quiz en ligne avec des designs originaux et plein de quiz : séries, films, manga et logique)</li>
                    </ul>
                </p>
                <h2>Utilisation des Informations</h2>
                <p>
                    <h5>Les informations que nous collectons sont utilisées pour :</h5>
                    <ul>
                        <li>Améliorer l'expérience utilisateur</li>
                        <li>Fournir un support client</li>
                        <li>Envoyer des mises à jour et des notifications</li>
                    </ul>
                    Nous utilisons également vos informations pour analyser les tendances et améliorer nos services. Ces données nous permettent de comprendre comment nos utilisateurs interagissent avec notre application et de faire des ajustements pour offrir une meilleure expérience utilisateur.
                </p>
                <h2>Partage des Informations</h2>
                <p>
                    Nous ne partageons pas vos informations personnelles avec des tiers, sauf si nécessaire pour fournir nos services ou si requis par la loi. Nous pouvons partager des informations agrégées et anonymes avec nos partenaires pour des fins d'analyse et de marketing.
                </p>
                <h2>Protection des Informations</h2>
                <p>
                    Nous utilisons des mesures de sécurité appropriées pour protéger vos informations contre tout accès, modification, divulgation ou destruction non autorisé. Nos mesures de sécurité incluent des technologies de cryptage, des pare-feux et des procédures d'accès sécurisé. Nous effectuons régulièrement des audits de sécurité pour assurer la protection continue de vos données.
                </p>
                <h2>Conservation des Données</h2>
                <p>
                    Nous conservons vos informations aussi longtemps que nécessaire pour fournir nos services et respecter nos obligations légales. Lorsque vos informations ne sont plus nécessaires, nous les supprimons de manière sécurisée. Vous avez le droit de demander la suppression de vos données à tout moment, et nous nous engageons à traiter ces demandes rapidement.
                </p>
                <h2>Contact</h2>
                <p>
                    Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à developpement.solution@gmail.com. Nous nous engageons à répondre à vos demandes dans les plus brefs délais et à vous fournir toute l'assistance nécessaire pour protéger votre vie privée.
                </p>
            </div>
        </>
    );
};

export default PrivacyPolicy;
