import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AddQuestionForm from './components/administration/AddQuestionForm';
import QuizInfoForm from './components/administration/QuizInfoForm';
import GeneralQuizPage from './components/quiz/GeneralQuizPage';
import Admin from './components/administration/Admin';
import Login from './components/administration/Login';
import Register from './components/administration/Register';
import Footer from './components/Footer';
import AdminDashboard from './components/administration/AdminDashboard';
import QuizSeriesPage from './components/quiz/QuizSeriesPage';
import QuizFilmsPage from './components/quiz/QuizFilmsPage';
import QuizMangaPage from './components/quiz/QuizMangaPage';
import QuizLogiquePage from './components/quiz/QuizLogiquePage';
import QuizStarPage from './components/quiz/QuizStarPage'; // Nouvelle page
import QuizCultureGeneralPage from './components/quiz/QuizCultureGeneralPage'; // Nouvelle page
import ContactForm from './components/ContactForm';
import QuizPage from './components/quiz/QuizPage';
import FooterInfo from './components/FooterInfo';
import About from './components/About';
import CGU from './components/CGU';
import CookiePolicy from './components/CookiePolicy';
import EditQuizForm from './components/administration/EditQuizForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = jwt.decode(token);
      if (!user) {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    }
  }, []);

  return (
    <Router>
      <div className="app">
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/quiz" element={<GeneralQuizPage />} />
            <Route path="/quiz/:slug" element={<QuizPage />} />
            <Route path="/quiz-series" element={<QuizSeriesPage />} />
            <Route path="/quiz-films" element={<QuizFilmsPage />} />
            <Route path="/quiz-manga" element={<QuizMangaPage />} />
            <Route path="/quiz-logique" element={<QuizLogiquePage />} />
            <Route path="/quiz-star" element={<QuizStarPage />} />
            <Route path="/quiz-culture-general" element={<QuizCultureGeneralPage />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/add-questions" element={<AddQuestionForm />} />
            <Route path="/create-quiz" element={<QuizInfoForm />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/register" element={isLoggedIn ? <Register /> : <Navigate to="/login" />} />
            <Route path="/admin-dashboard" element={isLoggedIn ? <AdminDashboard handleLogout={handleLogout} /> : <Navigate to="/login" />} />
            <Route path="/edit-quiz/:quizId" element={<EditQuizForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/cgu" element={<CGU />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
          </Routes>
        </div>
        <Footer />
        <FooterInfo />
      </div>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
