import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { injectIntl, FormattedMessage } from 'react-intl';
import QuizSlider from './QuizSlider';
import './Home.css';

const Home = ({ intl }) => {
  const pageTitle = intl.formatMessage({ id: 'app.startQuiz' });
  const description = intl.formatMessage({ id: 'app.description' });

  return (
    <div className="home-container">
      <Helmet>
        <title>{pageTitle || 'ABC Quiz'}</title>
        <meta name="description" content={description || 'ABC Quiz, votre destination pour des quiz en ligne interactifs sur les séries, films, manga et logique.'} />
        <meta name="keywords" content="quiz, séries, films, mangas, connaissances, interactif" />
        <meta property="og:title" content={`ABC Quiz - ${pageTitle || 'Accueil'}`} />
        <meta property="og:description" content={description || 'ABC Quiz, votre destination pour des quiz en ligne interactifs sur les séries, films, manga et logique.'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.abcquiz.fr/" />
        <meta property="og:image" content="URL_DE_TA_IMAGE" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`ABC Quiz - ${pageTitle || 'Accueil'}`} />
        <meta name="twitter:description" content={description || 'ABC Quiz, votre destination pour des quiz en ligne interactifs sur les séries, films, manga et logique.'} />
        <meta name="twitter:image" content="URL_DE_TA_IMAGE" />
        <link rel="canonical" href="https://www.abcquiz.fr/" />
      </Helmet>
      <article className="container">
        <h1 className='titrePri'><FormattedMessage id="app.header" /></h1>
        <p><FormattedMessage id="app.description" /></p>
        <QuizSlider />
        <p id="resume">
          <FormattedMessage id="app.longDescription" />
        </p>
      </article>
      <Link to="/quiz">
        <button className="start-quiz-button"><FormattedMessage id="app.startQuiz" /></button>
      </Link>
    </div>
  );
};

export default injectIntl(Home);
