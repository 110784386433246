import React, { useState } from 'react';
import axios from 'axios';
import './Admin.css';

function Admin() {
  const [questionText, setQuestionText] = useState('');
  const [category, setCategory] = useState('');
  const [answerOptions, setAnswerOptions] = useState([
    { answerText: '', isCorrect: false },
    { answerText: '', isCorrect: false },
    { answerText: '', isCorrect: false },
    { answerText: '', isCorrect: false },
  ]);

  const handleAnswerChange = (index, value, isCorrect) => {
    const newAnswerOptions = [...answerOptions];
    newAnswerOptions[index] = { answerText: value, isCorrect };
    setAnswerOptions(newAnswerOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newQuestion = {
      questionText,
      category,
      answerOptions,
    };
    await axios.post(`${process.env.REACT_APP_API_URL}/api/quiz;`, newQuestion);
    setQuestionText('');
    setCategory('');
    setAnswerOptions([
      { answerText: '', isCorrect: false },
      { answerText: '', isCorrect: false },
      { answerText: '', isCorrect: false },
      { answerText: '', isCorrect: false },
    ]);
  };

  return (
    <div className="admin-container">
      <div className="admin">
        <h2>Ajouter une nouvelle question</h2>
        <form onSubmit={handleSubmit} className="admin-form">
          <div className="form-group">
            <label>Question :</label>
            <input
              type="text"
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Catégorie :</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="form-control"
            >
              <option value="">Sélectionner une catégorie</option>
              <option value="Série">Série</option>
              <option value="Film">Film</option>
              <option value="Manga">Manga</option>
              <option value="Logique">Logique</option>
            </select>
          </div>
          {answerOptions.map((option, index) => (
            <div key={index} className="form-group">
              <label>Réponse {index + 1} :</label>
              <input
                type="text"
                value={option.answerText}
                onChange={(e) =>
                  handleAnswerChange(index, e.target.value, option.isCorrect)
                }
                className="form-control"
              />
              <label>
                Correcte :
                <input
                  type="checkbox"
                  checked={option.isCorrect}
                  onChange={(e) =>
                    handleAnswerChange(index, option.answerText, e.target.checked)
                  }
                  className="form-check"
                />
              </label>
            </div>
          ))}
          <button type="submit" className="btn-submit">Ajouter la question</button>
        </form>
      </div>
    </div>
  );
}

export default Admin;
