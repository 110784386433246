import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './EditQuizForm.css';

const EditQuizForm = () => {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);
  const [slug, setSlug] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/quiz/${quizId}`);
        const { title, category, image, slug } = response.data;
        setTitle(title);
        setCategory(category);
        setImage(image);
        setSlug(slug);
      } catch (error) {
        console.error('Error fetching quiz:', error);
        setError('Error fetching quiz details.');
      }
    };

    fetchQuiz();
  }, [quizId]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setSlug(e.target.value.toLowerCase().replace(/ /g, '-') + '-' + Math.floor(Math.random() * 1000));
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('slug', slug);
    if (image && typeof image !== 'string') {
      formData.append('image', image);
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/quiz/${quizId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/admin-dashboard');
    } catch (error) {
      console.error('Error updating quiz:', error);
      setError('Failed to update quiz.');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="edit-quiz-form-container">
      <form onSubmit={handleSubmit} className="edit-quiz-form">
        <div className="form-group">
          <label>Titre du Quiz:</label>
          <input type="text" value={title} onChange={handleTitleChange} required />
        </div>
        <div className="form-group">
          <label>Catégorie du Quiz:</label>
          <select value={category} onChange={handleCategoryChange} required>
            <option value="" disabled>Choisissez une catégorie</option>
            <option value="series">Série</option>
            <option value="films">Film</option>
            <option value="manga">Manga</option>
            <option value="logique">Logique</option>
            <option value="star">Star</option>
            <option value="cultureGeneral">Culture Générale</option>
          </select>
        </div>
        <div className="form-group">
          <label>Slug:</label>
          <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Image du Quiz:</label>
          <input type="file" onChange={handleImageChange} />
          {image && typeof image === 'string' && <img src={`${process.env.REACT_APP_API_URL}/uploads/${image}`} alt={title} className="edit-quiz-image-preview" />}
        </div>
        <button type="submit" className="btn-submit">Update Quiz</button>
      </form>
    </div>
  );
};

export default EditQuizForm;
