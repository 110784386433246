import React from 'react';
import './CGU.css'; // Assure-toi que le chemin est correct

const CGU = () => {
  return (
    <div className="cgu-page">
      <h1>Conditions Générales d'Utilisation</h1>
      <p>Voici nos CGU...<a href="/CGU.pdf" className="link-blue" target="_blank" rel="noopener noreferrer">CGU</a></p>
    </div>
  );
};

export default CGU;
