import React, { useState, useEffect } from 'react';
import './Question.css';

const Question = ({ question, onAnswerClick }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerOptionClick = (isCorrect, index) => {
    setSelectedAnswer(index);
    setTimeout(() => {
      onAnswerClick(isCorrect);
    }, 250); // Délai de 1 seconde pour afficher la couleur
  };

  useEffect(() => {
    setSelectedAnswer(null);
  }, [question]);

  return (
    <div className="question-container">
      <h2>{question.questionText}</h2>
      <div className="answer-options">
        {question.questionAnswerOptions.map((option, index) => (
          <button
            key={index}
            className={`answer-option ${selectedAnswer === index ? (option.isCorrect ? 'correct' : 'incorrect') : ''}`}
            onClick={() => handleAnswerOptionClick(option.isCorrect, index)}
          >
            {option.answerText}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Question;
