import React, { useEffect } from 'react';
import './CookiePolicy.css'; // Assurez-vous d'importer le fichier CSS

const CookiePolicy = () => {
  useEffect(() => {
    // Inclure le script Facebook SDK
    const script = document.createElement('script');
    script.src = "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v15.0&appId=600678444758275&autoLogAppEvents=1";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div id="scroll_to_top">
        <a href="#top"><img src="/retour.webp" className='retour' alt="Retourner en haut" /></a>
      </div>
      <div id="fb-root"></div>

      <h1 className="text-center">Politique des cookies</h1>

      <div className="container">
        <strong>
          <div className="ccm-cookie-declaration">Veuillez activer Javascript pour voir la liste de tous les cookies déclarés et des techniques similaires.</div>
        </strong>
        <div className="ccm-cookie-declaration" data-lang="fr_FR">Veuillez activer Javascript pour voir la liste de tous les cookies déclarés et des techniques similaires.</div>
      </div>
    </div>
  );
};

export default CookiePolicy;
