import React from 'react';
import { Link } from 'react-router-dom';
import './About.css'; // Assurez-vous d'avoir un fichier CSS

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>ABC Quiz</h1>
      <p>
        Nous vous proposons des quiz originaux pour vous amuser et tester vos connaissances sur les films et séries que vous regardez. 
        Dans un souci de transparence, nous vous indiquons que les quiz présents sur notre site sont uniquement construits grâce aux versions françaises.
        En cas de doutes sur l'une des réponses, vous pouvez nous contacter grâce au mail dans notre section CGU. Vous trouverez aussi une référence à la loi 
        sur le droit d'auteur ici. Les questionnaires sont réalisés pour que vous puissiez toujours trouver quelques réponses avec un minimum de connaissance 
        sur les séries, films et mangas que vous regardez. Nous essayons d'actualiser les questions le plus possible pour que notre site soit toujours attractif.
        N'hésitez pas à nous proposer vos idées de quiz ou améliorations que vous aimeriez voir.
      </p>
      <h2>A propos</h2>
      <p>
        Nouveau dans l'univers du développement web, j'ai fait ce site avant tout pour m'exercer, évoluer dans mon nouveau métier et faire plaisir aux personnes 
        qui comme moi aiment tester leurs connaissances, en proposant un site attractif (en tout cas je l'espère!) avec du contenu professionnel, une interface 
        ludique et plaisante, et des contenus en adéquation avec mes passions. Passionné par l'informatique, les séries, films et mangas, j'écris ces quiz afin 
        que vous passiez un bon moment de réflexion, d'amusement et de plaisir. N'hésitez pas à vous tester vous et vos amis. Vous retrouverez les nouveaux quiz 
        et les quiz à venir sur notre Facebook et notre Instagram!
      </p>
      <Link to="/" className="back-home">Retour à l'accueil</Link>
    </div>
  );
};

export default AboutUs;
