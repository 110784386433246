import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './QuizSlider.css';
import quizSeriesImage from '../assets/images/quiz-serie.png';
import quizFilmsImage from '../assets/images/quiz-film.png';
import quizMangaImage from '../assets/images/quiz-manga.png';
import quizLogiqueImage from '../assets/images/quiz-logique.png';
import quizStarImage from '../assets/images/quiz-star.webp'; // Nouvelle image
import quizCultureGeneralImage from '../assets/images/quiz-culture-général.webp'; // Nouvelle image
import customImage1 from '../assets/images/Hunter_X_Hunte de Thien Tan NGUYEN.webp';
import customImage2 from '../assets/images/Illustration réalisée par Quentin Jay.webp';
import customImage3 from '../assets/images/Iron réaliser par Sofiane Chabane.webp';

const QuizSlider = () => {
  useEffect(() => {
    const images = document.querySelectorAll('.quiz-slider img');
    images.forEach((img) => {
      let timer;
      const surpriseMessage = document.createElement('div');
      surpriseMessage.classList.add('surprise-message');
      surpriseMessage.innerText = "Surprise!";
      img.parentElement.style.position = 'relative'; // Assurez-vous que le parent a une position relative
      img.parentElement.appendChild(surpriseMessage);

      img.addEventListener('mouseenter', () => {
        timer = setTimeout(() => {
          surpriseMessage.style.opacity = '1';
        }, 4000); // 4 secondes
      });

      img.addEventListener('mouseleave', () => {
        clearTimeout(timer);
        surpriseMessage.style.opacity = '0';
      });
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  const customSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000, /* Défilement plus lent pour le custom slider */
  };

  return (
    <div className="quiz-slider">
      <Slider {...settings}>
        <div>
          <Link to="/quiz-series">
            <img src={quizSeriesImage} alt="Quiz Série" />
            <h3>Quiz Série</h3>
          </Link>
        </div>
        <div>
          <Link to="/quiz-films">
            <img src={quizFilmsImage} alt="Quiz Film" />
            <h3>Quiz Film</h3>
          </Link>
        </div>
        <div>
          <Link to="/quiz-manga">
            <img src={quizMangaImage} alt="Quiz Manga" />
            <h3>Quiz Manga</h3>
          </Link>
        </div>
        <div>
          <Link to="/quiz-logique">
            <img src={quizLogiqueImage} alt="Quiz Logique" />
            <h3>Quiz Logique</h3>
          </Link>
        </div>
        <div>
          <Link to="/quiz-star">
            <img src={quizStarImage} alt="Quiz Star" />
            <h3>Quiz Star</h3>
          </Link>
        </div>
        <div>
          <Link to="/quiz-culture-general">
            <img src={quizCultureGeneralImage} alt="Quiz Culture Générale" />
            <h3>Quiz Culture Générale</h3>
          </Link>
        </div>
      </Slider>
      <Slider {...customSettings} className="custom-slider">
        <div>
          <img src={customImage1} alt="Hunter_X_Hunte de Thien Tan NGUYEN.webp" />
        </div>
        <div>
          <img src={customImage2} alt="Illustration réalisée par Quentin Jay.webp" />
        </div>
        <div>
          <img src={customImage3} alt="Iron réaliser par Sofiane Chabane.webp" />
        </div>
      </Slider>
    </div>
  );
};

export default QuizSlider;
