import React from 'react';
import './FooterInfo.css'; // Assurez-vous d'importer le fichier CSS

const FooterInfo = () => (
  <div className="footer-info">
    <p className="text-create">Created by <a className='devsite' href="https://developpement-solution.fr/" target="_blank" rel="noopener noreferrer">Développement-solution</a> © 2022 V 1.4.0</p>
  </div>
);

export default FooterInfo;
