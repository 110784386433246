export const messages = {
  en: {
    'app.header': 'Welcome to the ABCQuiz!',
    'app.startQuiz': 'Start the Quiz',
    'app.description': 'Welcome to ABC Quiz! Test your knowledge with our interactive quizzes on your favorite series, movies, mangas, and more.',
    'app.longDescription': 'We welcome you to our site with many quizzes to test your knowledge on your favorite series, movies, mangas, and more. The quiz questions are based on French versions; neither the books nor comments found here and there are taken into account in the creation of the quizzes. Even difficult questions can find answers by eliminating wrong answers or with luck. The questions are carefully prepared and chosen to be relevant to the chosen theme. You can always find the privacy rules on our CGU page. Cookies are collected for statistical purposes; you can refuse them or change your choice if you have accepted them using the small symbol at the bottom of the page. You can contact support at any time if you have a suggestion or a request on our Contact form. You can share your scores, do the quizzes with friends or family, and even challenge each other. We wish you a good experience on our site, it\'s your turn to play. Quiz sites are a fun way to learn new things. They can be used to find out which Harry Potter house you belong to or to know which Disney princess you resemble the most. Quiz sites are also a great way to learn more about yourself and the things that interest you. Quiz sites have existed for many years. However, they have become increasingly popular in recent years as people have started using them for purposes other than entertainment. There are now quiz sites on topics such as careers, hobbies, and interests that help people find new passions and possible jobs.',
    'quiz.title': 'Quiz Title',
    'quiz.numQuestions': '{numQuestions} questions',
    'quiz.startButton': 'Start Quiz',
    'category.series': 'Series',
    'category.films': 'Films',
    'category.manga': 'Manga',
    'category.logic': 'Logic',
    'category.star': 'Star',
    'category.cultureGeneral': 'General Culture'
  },
  fr: {
    'app.header': 'Bienvenue sur ABCQuiz !',
    'app.startQuiz': 'Commencer le Quiz',
    'app.description': 'Bienvenue sur ABC Quiz! Testez vos connaissances avec nos quiz interactifs sur vos séries, films, mangas préférés et plus encore.',
    'app.longDescription': 'Nous vous accueillons sur notre site avec plein de quiz pour tester vos connaissances sur vos séries, films, mangas préférés et plus encore. Les questions des quiz sont basées sur les versions françaises, ni les livres ni les commentaires que l\'on peut trouver ici et là ne sont pris en compte dans la réalisation des quiz. Les questions même difficiles peuvent trouver réponse par élimination des mauvaises réponses ou avec de la chance. Les questions sont minutieusement préparées et choisies afin d\'avoir une pertinence par rapport au thème choisi. Vous pouvez à tout moment retrouver les règles de confidentialité sur notre page CGU. Les cookies sont collectés dans un but statistique, vous pouvez les refuser ou revenir sur votre choix si vous les avez acceptés avec le petit symbole en bas de page. Vous pouvez contacter le support à tout moment si vous avez une suggestion ou une demande sur notre formulaire de Contact. Vous pouvez partager vos scores, faire les quiz entre amis ou en famille et même vous lancer des défis. Nous vous souhaitons une bonne expérience sur notre site, à vous de jouer. Les sites de quiz sont une façon amusante d\'apprendre de nouvelles choses. Ils peuvent être utilisés pour savoir à quelle maison Harry Potter vous appartenez ou pour savoir à quelle princesse Disney vous ressemblez le plus. Les sites de quiz sont également un excellent moyen d\'en savoir plus sur vous-même et sur les choses qui vous intéressent. Les sites de quiz existent depuis de nombreuses années. Cependant, ils sont devenus de plus en plus populaires ces dernières années, car les gens ont commencé à les utiliser à des fins autres que de divertissement. Il existe maintenant des sites de quiz sur des sujets tels que les carrières, les loisirs et les intérêts qui aident les gens à trouver de nouvelles passions et des emplois possibles.',
    'quiz.title': 'Titre du Quiz',
    'quiz.numQuestions': 'Nombre de questions: {numQuestions}',
    'quiz.startButton': 'Commencer le Quiz',
    'category.series': 'Séries',
    'category.films': 'Films',
    'category.manga': 'Manga',
    'category.logic': 'Logique',
    'category.star': 'Star',
    'category.cultureGeneral': 'Culture Générale'
  },
};
