import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './QuizInfoForm.css'; // Importez le fichier CSS

const QuizInfoForm = () => {
  const [quizTitle, setQuizTitle] = useState('');
  const [quizImage, setQuizImage] = useState(null);
  const [quizCategory, setQuizCategory] = useState('');
  const [numQuestions, setNumQuestions] = useState(0);
  const navigate = useNavigate();

  const handleQuizTitleChange = (e) => {
    setQuizTitle(e.target.value);
  };

  const handleQuizImageChange = (e) => {
    setQuizImage(e.target.files[0]);
  };

  const handleQuizCategoryChange = (e) => {
    setQuizCategory(e.target.value);
  };

  const handleNumQuestionsChange = (e) => {
    setNumQuestions(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('Title:', quizTitle);
    console.log('Category:', quizCategory);
    console.log('Num Questions:', numQuestions);
  
    const formData = new FormData();
    formData.append('title', quizTitle);
    formData.append('image', quizImage);
    formData.append('category', quizCategory);
    formData.append('numQuestions', numQuestions);
    formData.append('slug', quizTitle.toLowerCase().replace(/ /g, '-') + '-' + Math.floor(Math.random() * 1000));
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/quiz/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const quizDetails = { quizId: response.data.id, numQuestions };
      navigate('/add-questions', { state: { quizDetails } });
    } catch (error) {
      console.error('Error creating quiz:', error);
      alert('Failed to create quiz.');
    }
  };

  return (
    <div className="quiz-info-form-container">
      <form onSubmit={handleSubmit} className="quiz-info-form">
        <div className="form-group">
          <label>Titre du Quiz:</label>
          <input type="text" value={quizTitle} onChange={handleQuizTitleChange} required />
        </div>
        <div className="form-group">
          <label>Image du Quiz:</label>
          <input type="file" onChange={handleQuizImageChange} />
        </div>
        <div className="form-group">
          <label>Catégorie du Quiz:</label>
          <select value={quizCategory} onChange={handleQuizCategoryChange} required>
            <option value="" disabled>Choisissez une catégorie</option>
            <option value="series">Séries</option>
            <option value="films">Films</option>
            <option value="manga">Manga</option>
            <option value="logique">Logique</option>
            <option value="star">Star</option>
            <option value="cultureGeneral">Culture Générale</option>
          </select>
        </div>
        <div className="form-group">
          <label>Nombre de Questions:</label>
          <input type="number" value={numQuestions} onChange={handleNumQuestionsChange} required />
        </div>
        <button type="submit" className="btn-submit">Créer le Quiz</button>
      </form>
    </div>
  );
};

export default QuizInfoForm;
