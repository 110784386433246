import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
  e.preventDefault();
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
      username,
      password,
    });
    console.log(res.data); // Log pour vérifier la réponse du serveur
    if (res.data.token) {
      localStorage.setItem('token', res.data.token);
      setIsLoggedIn(true);
      navigate('/admin-dashboard'); // Rediriger vers le tableau de bord admin après connexion réussie
    } else {
      setNotification({ type: 'error', message: res.data.message || 'Erreur lors de la connexion' });
      setTimeout(() => setNotification(null), 3000);
    }
  } catch (error) {
    console.error('Erreur lors de la connexion:', error);
    setNotification({ type: 'error', message: 'Erreur lors de la connexion' });
    setTimeout(() => setNotification(null), 3000);
  }
};


  return (
    <div className="login-container">
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
      <div className="login">
        <h2>Connexion</h2>
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label>Nom d'utilisateur :</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>Mot de passe :</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <button type="submit" className="btn-submit">Se connecter</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
