import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setNotification({ type: 'error', message: 'Les mots de passe ne correspondent pas' });
      setTimeout(() => setNotification(null), 3000);
      return;
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, { // Assurez-vous que l'URL est correcte
        username,
        password,
      });
      if (res.data.success) {
        setNotification({ type: 'success', message: 'Utilisateur créé avec succès' });
        setTimeout(() => {
          setNotification(null);
          navigate('/login');
        }, 3000);
      } else {
        setNotification({ type: 'error', message: res.data.message || 'Erreur lors de l\'inscription' });
        setTimeout(() => setNotification(null), 3000);
      }
    } catch (error) {
      console.error('Erreur lors de l\'inscription:', error);
      setNotification({ type: 'error', message: 'Erreur lors de l\'inscription' });
      setTimeout(() => setNotification(null), 3000);
    }
  };

  return (
    <div className="register-container">
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
      <div className="register">
        <h2>Inscription Administrateur</h2>
        <form onSubmit={handleRegister} className="register-form">
          <div className="form-group">
            <label>Nom d'utilisateur :</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>Mot de passe :</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>Confirmer le mot de passe :</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <button type="submit" className="btn-submit">inscrire</button>
        </form>
      </div>
    </div>
  );
}

export default Register;
